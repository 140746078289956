<template>
  <div class="page-thank-you">
    <div class="container">
      <h1 class="thank-you__title">Grazie per la vostra richiesta!</h1>
      <p class="thank-you__body">
        I professionisti ti contatteranno al più presto.
      </p>
    </div>

    <div class="container">
      <div id="usps" class="usps__container">
        <h2 class="usps__title">Suggerimenti da seguire</h2>
        <Usps :usps="usps" />
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  components: {
    Usps,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Preventivo completato',
      headDescription:
        'Inserisci il tuo codice postale e confronta 6 preventivi gratuiti dalle migliori aziende della tua zona. Risparmia fino al 40% ✓100% gratis!',
      path: '/richieste-di-preventivi/preventivo-completato',
      usps: [
        {
          text: 'Assicurati di avere il tuo telefono e calendario a portata di mano, perché i professionisti possono contattarti telefonicamente.',
          logo: require('chimera/all/themes/blueflow/images/usps/calendar.svg'),
        },
        {
          text: 'Non guardare solo al prezzo, ma confrontare anche qualità, servizio e affidabilità.',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          text: 'Prenditi il tuo tempo per scegliere il professionista giusto.',
          logo: require('chimera/all/themes/blueflow/images/usps/safety.svg'),
        },
      ],
    }
  },
}
</script>
