<template>
  <div class="w-full">
    <t-form
      ref="form"
      class="postal-form-step flex-col items-center"
      @submit="onSubmit"
      @submit.prevent
    >
      <div class="form-modal__title">
        {{ $t('phone-validation.title') }}
      </div>

      <div class="form-modal__subtitle">
        {{ $t('phone-validation.subtitle') }}
      </div>

      <p class="text-center font-bold leading-6 my-6">
        {{ $t('phone-validation.instructions') }}
        <span class="text-primary">{{ phone.number }}</span>
      </p>

      <div class="max-w-[280px]">
        <div class="flex justify-center w-full">
          <div class="flex gap-x-2">
            <PhoneVerificationFormPart
              :error-messages="errorMessages"
              :length="length"
              @update-value="onUpdateValue"
            />
          </div>
        </div>

        <FormErrorMessages
          :error-messages="errorMessages"
          class="block text-sm text-error text-center my-2"
        />

        <div class="flex justify-between mt-3">
          <t-btn
            class="text-sm text-primary px-0 border-0 h-auto"
            @click="sendCode(phone, countryCode)"
          >
            {{ $t('phone-validation.send-sms') }}
          </t-btn>

          <t-btn
            class="text-sm text-primary px-0 border-0 h-auto"
            @click="onEditButtonClicked"
          >
            {{ $t('phone-validation.edit-number') }}
          </t-btn>
        </div>

        <t-btn
          id="validatePhone"
          :class="[
            value.length < 6 || isVerifying
              ? 'bg-gray-400 cursor-not-allowed pointer-events-none'
              : 'bg-primary',
          ]"
          class="t-btn--block mt-9 mb-4"
          @click="verify(phone.number, value)"
        >
          <div class="flex items-center gap-x-1">
            <svg
              v-if="isVerifying"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              />
              <path
                class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"
              />
            </svg>

            {{ $t('phone-validation.validate') }}
          </div>
        </t-btn>
      </div>

      <LeadSubmitFormPart
        ref="leadSubmit"
        :is-optional="true"
        hidden
        @change="onChange"
        @leadPostError="onLeadPostError"
        @leadPostInvalid="onLeadPostInvalid"
        @leadPostValid="onLeadPostValid"
        @result="onResult"
      />
    </t-form>
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import LeadSubmitFormPart from 'chimera/all/components/form/parts/LeadSubmitFormPart'
import PhoneVerificationFormPart from 'chimera/all/components/form/parts/phone/PhoneVerificationFormPart.vue'
import ErrorPage from 'chimera/all/themes/blueflow/page/ErrorPage'
import { EventBus } from 'chimera/all/plugins/eventbus'
import parsePhoneNumber from 'chimera/all/functions/phoneParser'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics'
import ThankYouPage from '~/pages/richieste-di-preventivi/preventivo-completato'

/**
 * Our supported experiment custom dimensions
 *
 * @typedef TwilioVerifyEndpoint
 * @enum {TwilioVerifyEndpoint}
 */
export const TwilioVerifyEndpoint = {
  VERIFICATIONS: 'Verifications',
  VERIFICATION_CHECK: 'VerificationCheck',
}

const PhoneVerified = 'phone_verified'
const PhoneNotVerified = 'phone_not_verified'

export default {
  name: 'PhoneVerifyFormStep',

  components: {
    FormErrorMessages,
    PhoneVerificationFormPart,
    LeadSubmitFormPart,
  },

  extends: AbstractFormStep,

  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
  },

  /**
   * @returns {{
   * twilioAccountSID: string,
   * twilioAccountAuthToken: string,
   * twilioAccountAuthToken: string,
   * length: number,
   * value: string,
   * errorMessages: [],
   * isVerifying: boolean
   * }}
   */
  data() {
    return {
      twilioAccountSID: 'AC3734bd8ab23c87de03e9e5f70cc74f1f',
      twilioAccountAuthToken: '952eb206fb8039522c65bee6b78e49c4',
      twilioServiceSID: 'VA38738c4232d9c81ce87e6c4b580ae3dc',
      length: 6,
      value: '',
      errorMessages: [],
      isVerifying: false,
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    countryCode() {
      return this.$store.getters['context/get']('country', 'nl')
    },

    /**
     * @returns {object}
     */
    phone() {
      return parsePhoneNumber(this.phoneNumber, this.countryCode)
    },
  },

  /** */
  mounted() {
    this.sendCode(this.phone, this.countryCode)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     */
    onEditButtonClicked() {
      this.$gtag.event(
        'phone_verification_edit_clicked',
        createScopedEvent(getEventScope()),
      )

      this.$router.back()
    },

    /**
     * @param formData
     * @param {TwilioVerifyEndpoint} endpoint
     * @returns {Promise<Response>}
     */
    makeTwilioRequest(formData, endpoint) {
      const authHeader = btoa(
        this.twilioAccountSID + ':' + this.twilioAccountAuthToken,
      )
      const myHeaders = new Headers()
      myHeaders.append('Authorization', 'Basic ' + authHeader)

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow',
      }

      const url = `https://verify.twilio.com/v2/Services/${this.twilioServiceSID}/${endpoint}`
      return fetch(url, requestOptions)
    },

    /**
     * @param {object} phone
     * @param {string} phone.number
     * @param {string} phone.type
     * @param {string} locale
     */
    sendCode({ number, type }, locale) {
      this.errorMessages = []

      const channel = type === 'MOBILE' ? 'sms' : 'call'
      const formData = new FormData()
      formData.append('To', number)
      formData.append('Locale', locale)
      formData.append('Channel', channel)

      this.makeTwilioRequest(formData, TwilioVerifyEndpoint.VERIFICATIONS)
        .then((response) => response.json())
        .then(this.onSendResult)
        .catch(this.onSendError)
    },

    /**
     *
     * @param result
     */
    onSendResult(result) {
      let errorMessage
      switch (result.code) {
        case 60205: // 60205 - SMS is not supported by landline phone number
          errorMessage = this.$i18n.t(
            'phone-validation.result-codes.sms-is-not-supported',
          )
          break
        case 60202: // 60202 - Max (5) verification check attempts reached.
          errorMessage = this.$i18n.t(
            'phone-validation.result-codes.max-attempts-reached',
          )
          break
        case 60203: // 60203 - This rate limit is triggered when the verification lifecycle (sending and checking) is not completed.
          errorMessage = this.$i18n.t(
            'phone-validation.result-codes.rate-limit-triggered',
          )
          // APP ERROR
          EventBus.emitErrorAppErrorEvent(new Error(result.message), result)
          break
        case 60212: // 60212 - Too many concurrent requests for phone number
          errorMessage = this.$i18n.t(
            'phone-validation.result-codes.too-many-concurrent-requests',
          )
          // APP ERROR
          EventBus.emitErrorAppErrorEvent(new Error(result.message), result)
          break
        case 60401: // 60401 - An error occurred while calling the API.
        case 68001: // 68001 - An error occurred while calling the API.
          // APP ERROR
          EventBus.emitErrorAppErrorEvent(new Error(result.message), result)
          break
        default:
          break
      }

      if (errorMessage) {
        this.errorMessages.push(errorMessage)
      }
    },

    /**
     *
     * @param {Error} error
     */
    onSendError(error) {
      this.emitTransitionOnError(error)
    },

    /**
     * @param {string} to
     * @param {string} code
     */
    verify(to, code) {
      this.errorMessages = []
      this.isVerifying = true

      const formData = new FormData()
      formData.append('To', to)
      formData.append('Code', code)

      this.makeTwilioRequest(formData, TwilioVerifyEndpoint.VERIFICATION_CHECK)
        .then((response) => response.json())
        .then(this.onVerifyResult)
        .catch(this.onVerifyError)
    },

    /**
     * @returns {boolean}
     */
    isPhoneEligible() {
      const phoneNumber = this.$store.getters['lead/getData']('phone')
      const countryCode = this.$store.getters['context/get']('country')
      const parsedNumber = parsePhoneNumber(phoneNumber, countryCode)
      return ['MOBILE', 'FIXED_LINE'].includes(parsedNumber.type)
    },

    /**
     * @param {object} result
     */
    onVerifyResult(result) {
      if (result.valid && this.isPhoneEligible()) {
        this.transition(true)
        return
      }

      this.isVerifying = false
      this.errorMessages.push(
        this.$i18n.t('phone-validation.verify-result.error-message'),
      )
    },

    /**
     * @param value
     */
    onUpdateValue(value) {
      this.value = value
    },

    /**
     * @param {Error} error
     */
    onVerifyError(error) {
      this.emitTransitionOnError(error)
    },

    /**
     * @param {Error} error
     */
    emitTransitionOnError(error) {
      // These should be unrecoverable errors
      EventBus.emitErrorAppErrorEvent(error, {})

      // Just transition instead of redirecting to the error page
      this.transition()
    },

    /**
     * @param {boolean} isVerified
     */
    async transition(isVerified = false) {
      await this.$store.dispatch('lead/add', {
        key: 'custom-dimension-1',
        value: isVerified ? PhoneVerified : PhoneNotVerified,
      })

      this.trackTransition(isVerified)

      this.$refs.leadSubmit.postLead()
    },

    /**
     * Track the transition
     *
     * @param {boolean} isVerified
     */
    trackTransition(isVerified) {
      this.$gtag.event(
        'phone_verification',
        createScopedEvent(getEventScope(), {
          type: this.phone.type,
          hasSkipped: !isVerified,
        }),
      )
    },

    /**
     * On successful lead post
     */
    onLeadPostValid() {
      this.routeTo(ThankYouPage)
    },

    /**
     * On invalid lead post
     */
    onLeadPostInvalid() {
      this.routeTo(ErrorPage)
    },

    /**
     * Lead post resulted in an error
     */
    onLeadPostError() {
      this.routeTo(ErrorPage)
    },
  },
}
</script>
